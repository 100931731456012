import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, CardContent, Typography, CardMedia, CircularProgress, Box, Button } from '@material-ui/core';
import { formatDistanceToNow, isWithinInterval } from 'date-fns';
import CloudIcon from '@material-ui/icons/Cloud';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import OpacityIcon from '@material-ui/icons/Opacity';

const DeviceManager = () => {
  const [devices, setDevices] = useState([]);
  const [deviceImages, setDeviceImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trialInfo, setTrialInfo] = useState([]);
  const [sensorInfo, setSensorInfo] = useState([]);

  const boxStyle = {
    border: '1px solid lightgray',
    borderRadius: '8px',
    padding: '8px',
    //marginBottom: '8px',
    margin: '4px',
  };

  const buttonStyle = {
      backgroundColor: 'green',
      color: 'white',
      '&:hover': {
        backgroundColor: 'darkgreen',
      },

  };

  // First hook to fetch devices
  useEffect(() => {
    const fetchDevices = async () => {
      const domainData = localStorage.getItem('domain');
      let domain;
      if (domainData) {
        try {
          const parsedDomain = JSON.parse(domainData);
          domain = Array.isArray(parsedDomain) ? parsedDomain[0] : parsedDomain;
          
          if (typeof domain === 'string') {
            const response = await axios.get(`/devices/${domain}`);
            setDevices(response.data);
            console.log('Devices: ', response.data);
          }
        } catch (error) {
          console.error('Failed to fetch devices or parse domain:', error);
        }
      }
    };
  
    fetchDevices();
  }, []);

  // Second hook fetches images, dependent on the devices fetched by the first hook
  useEffect(() => {
    const fetchDeviceImages = async () => {
      if (devices.length > 0) {
        setIsLoading(true); // Set loading to true when starting to fetch images
        const payload = { deviceIDs: devices.map(device => device._id) };
        console.log("Payload for /getImages/latest-images/:", payload);
        const response = await axios.post('/getImages/latest-images/', payload);
        console.log("Response from /getImages/latest-images/:", response.data);
        setDeviceImages(response.data);
        setIsLoading(false); // Set loading to false once images are fetched
      }
    };

    fetchDeviceImages();
  }, [devices]); // This runs whenever the devices state changes

  // Third hook fetches trial info, dependent on the devices fetched by the first hook
  useEffect(() => {
    const fetchTrialInfo = async () => {
      if (devices.length > 0) {
        try {
          const deviceIDs = devices.map(device => device._id); // Adjust based on your device state structure
          const response = await axios.post('/trials/current-trials', {
            deviceIDs: deviceIDs,
          });
          console.log("Response from /trials/current-trials:", response.data);

          const trials = response.data.map(trial => ({
            deviceId: trial.device_id,
            trialName: trial.trial_name,
            trialId: trial._id,
            experimentName: trial.experiment_name,
            start_date: trial.start_date,
          }));

            setTrialInfo(trials);
          } catch (error) {
            console.error('Failed to fetch trial info:', error);
          }
        }
    };

    fetchTrialInfo();
  }, [devices]);

  // Fourth hook fetches sensor data, dependent on the trials fetched by the third hook
  useEffect(() => {
    const fetchSensorData = async () => {
      if (trialInfo.length > 0) {
        console.log("Trial info:", trialInfo);
        const trialIds = trialInfo.map(trial => trial.trialId);
        console.log("Trial IDs for /sensor-data:", trialIds);
        try {
          const response = await axios.post('/environment/sensor-data', {
            trialIds: trialIds,
          });
          setSensorInfo(response.data);
          console.log("Response from /environment/sensor-data:", response.data);
        } catch (error) {
          console.error('Failed to fetch sensor data:', error);
        }
      }
    };

    fetchSensorData();
  }, [trialInfo]);

  // Function to find the image object for a device
  const findImageForDevice = (deviceId) => {
    //console.log("Searching for:", deviceId);
    deviceImages.forEach(img => img.deviceID);
    return deviceImages.find((img) => img.deviceID === deviceId);
  };

  const handleFlashLEDClick = async (deviceID) => {
    try {
      const response = await axios.get(`/devices/flash-LED/${deviceID}`);
      console.log(response.data); // Process the response as needed
    } catch (error) {
      console.error('There was a server error!', error);
    }
  };  

  // Formatting for sensor data observation dates
  const formatLocalDateString = (dateString) => {
    // Remove 'Z' to avoid treating the date as UTC
    const localDateString = dateString.replace('Z', '');
    // Convert to Date object and format
    return new Date(localDateString).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short', // "Jan", "Feb", etc.
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      // timeZoneName: 'short' is omitted to avoid appending timezone information
    });
  };

  // Function to determine device status and last connected time
  const getStatusAndLastConnected = (lastHeartbeat) => {
    // Check if lastHeartbeat is undefined or null
    if (!lastHeartbeat) {
      return {
        status: 'Please update firmware',
        statusColor: 'red', // Use red or any color that indicates attention is needed
        lastConnected: '',
      };
    }
  
    const lastHeartbeatDate = new Date(lastHeartbeat);
    const sixMinutesAgo = new Date(Date.now() - 6 * 60 * 1000);
    const isOnline = isWithinInterval(lastHeartbeatDate, { start: sixMinutesAgo, end: new Date() });
  
    return {
      status: isOnline ? 'Online' : 'Offline',
      statusColor: isOnline ? 'green' : 'grey',
      lastConnected: `Latest Ping: ${formatDistanceToNow(lastHeartbeatDate, { addSuffix: true })}`,
    };
  };

  return (
    <Grid container spacing={2}>
      {devices.map((device) => {
        const imageInfo = findImageForDevice(device._id);
        const trial = trialInfo.find(t => t.deviceId === device._id) || {};
        const sensorData = sensorInfo.find(info => info.trialId === trial.trialId) || { data: [] };
        const { status, statusColor, lastConnected } = getStatusAndLastConnected(device.last_heartbeat);

        // Extract sensor readings by attribute
        const temperature = sensorData.data.find(d => d.attribute === 'temperature');
        const humidity = sensorData.data.find(d => d.attribute === 'humidity');
        const co2 = sensorData.data.find(d => d.attribute === 'co2');


        return (
          <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={device._id}>
            <Card style={boxStyle}>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
                  <Typography variant="h6" component="h2">
                    Device: {device.device_name}
                  </Typography>
                  <Button variant="contained" style={buttonStyle} onClick={() => handleFlashLEDClick(device._id)}>
                    Flash LED
                  </Button>
                </Box>
                
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Status:</span> <span style={{ marginLeft: '4px', color: statusColor }}>{status}</span>
                  </Typography>
                  
                  {lastConnected && (
                    <Typography variant="body2" style={{ color: 'grey' }}>
                      {lastConnected}
                    </Typography>
                  )}
                </Box>

                {/* Experiment Status Section */}
                <Box style={boxStyle}>
                  <Typography variant="body2">
                    <Box component="span" fontWeight="bold">Experiment:</Box> {trial.experimentName || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    <Box component="span" fontWeight="bold">Trial:</Box> {trial.trialName || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    <Box component="span" fontWeight="bold">Trial Age:</Box> {trial.start_date ? `${formatDistanceToNow(new Date(trial.start_date), { addSuffix: false })}` : 'N/A'}
                  </Typography>
                </Box>

                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  <Box flexGrow={1} style={boxStyle}>
                    {/* Image and Timestamp */}
                    <Typography variant="subtitle1">Latest Image Received</Typography>
                    {isLoading ? (
                      <CircularProgress />
                    ) : imageInfo && imageInfo.imageURL ? (
                      <>
                        <CardMedia
                          component="img"
                          alt={`Image of ${device.device_name}`}
                          style={{
                            height: '140px',
                            width: 'auto',
                            objectFit: 'cover',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block'
                          }}
                          image={imageInfo.imageURL}
                        />
                        <Typography variant="body2" color="textSecondary" component="p">
                          {imageInfo.timestamp ? new Date(imageInfo.timestamp).toLocaleString() : "No Timestamp Found"}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body2" component="p">
                        No Image Found
                      </Typography>
                    )}
                  </Box>

                  <Box style={{...boxStyle, display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Typography variant="subtitle1" style={{ marginBottom: '8px'}}>Latest Sensor Readings</Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" alignItems="center">
                        <WhatshotIcon color="secondary" />
                        <Typography variant="body2">
                          Temp: {temperature ? `${temperature.value} ${temperature.unit}` : 'No data found for this trial'}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        {temperature ? formatLocalDateString(temperature.observation_date) : ''}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" alignItems="center">
                        <OpacityIcon color="primary" />
                        <Typography variant="body2">
                          Humidity: {humidity ? `${humidity.value} ${humidity.unit}` : 'No data found for this trial'}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        {humidity ? formatLocalDateString(humidity.observation_date) : ''}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" alignItems="center">
                        <CloudIcon color="tertiary" />
                        <Typography variant="body2">
                          CO2: {co2 ? `${co2.value} ${co2.unit}` : 'No data found for this trial'}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        {co2 ? formatLocalDateString(co2.observation_date) : ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DeviceManager;
